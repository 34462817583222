import { useState } from "react"
import styled from "styled-components"

import { subDays } from "date-fns"

import { ExportDeviceDataDialog } from "src/components/Homes/ExportDeviceDataDialog/ExportDeviceDataDialog"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { spacing } from "src/ui/spacing"

import { DeviceGraphs, IGraphDateRange } from "./DeviceGraphs"
import { LatestSensorValues } from "./LatestSensorValues"

export function DeviceOverview({
  device,
  home,
}: {
  device: TDevice
  home: THome
}) {
  const { t, langKeys } = useTranslate()

  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 1),
    endDate: new Date(),
  })

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })
  }

  const fetchProfile = useFetchProfile({
    id: home?.profile_id || "",
    options: { enabled: !!home?.profile_id },
  })

  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  /**  We only want to show the instantlyTurnOnAlarm note hint when it makes sense
  for the user to see it, which is when the request has finished loading and
  when the instantly_turn_on is set on the security_alarm object. When it isn't
  or if the requests fails (it can fail due to the user not having permissons
  to fetch profiles) then we don't want to show anything */
  const instantlyTurnOnAlarm =
    fetchProfile.data?.security_alarm?.instantly_turn_on ?? null

  return (
    <div>
      {!!device && <LatestSensorValues home={home} device={device} />}

      {!!home && (
        <>
          <ActionsBox>
            <MButtonLegacy
              variant="text"
              onClick={() => setExportDialogOpen(true)}
            >
              {t(langKeys.export)}
            </MButtonLegacy>

            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={handleDateRangeChange}
              dateRangeProps={{
                minBookingDays: 2,
              }}
              defaultPresetKey="DAY"
              calendarPlacement="top-end"
            />
          </ActionsBox>

          <DeviceGraphs
            device={device}
            home={home}
            dateRange={dateRange}
            instantlyTurnOnAlarm={instantlyTurnOnAlarm}
          />
        </>
      )}

      <ExportDeviceDataDialog
        device={device}
        home={home}
        open={exportDialogOpen}
        dateRange={dateRange}
        onClose={() => setExportDialogOpen(false)}
      />
    </div>
  )
}

const ActionsBox = styled.div`
  position: sticky;
  top: 0;
  padding: ${spacing.M} 0;
  background: white;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  gap: ${spacing.S};
`
